import { PaddockSelectionPage } from '@/features/paddock-groups/components/create-steps/PaddockSelection'
import {
  useAddPaddocksToGroup,
  useRemovePaddocksFromGroup,
} from '@/features/paddock-groups/api/paddockGroupsApi'
import { PaddockListItem } from '@/features/paddocks/components/PaddockListItem'
import { Paddock } from '@/features/paddocks/types/Paddock.types'
import { useUserContext } from '@/providers/user'
import { ActionIcon, Drawer, Group, Paper, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { difference } from 'lodash'
import { useTranslation } from 'react-i18next'
import { FaPencilAlt } from 'react-icons/fa'
import { useParams } from 'react-router-dom'

type PaddockGroupViewPaddocksProps = {
  paddocks: Paddock[]
}

export const PaddockGroupViewPaddocks = ({ paddocks }: PaddockGroupViewPaddocksProps) => {
  const { t } = useTranslation('paddock-group')
  const { id } = useParams()
  const { farm } = useUserContext()
  const [opened, { open, close }] = useDisclosure(false)

  const removePaddocksFromGroupMutation = useRemovePaddocksFromGroup(farm?.id.toString(), id)
  const addPaddocksToGroupMutation = useAddPaddocksToGroup(farm?.id.toString(), id)

  const handleNextClick = async (selectedPaddocks: Paddock[]) => {
    close()
    const removedPaddocks = difference(paddocks, selectedPaddocks).map((p) => p.id)
    const addedPaddocks = difference(selectedPaddocks, paddocks).map((p) => p.id)

    if (removedPaddocks && removedPaddocks.length > 0) {
      await removePaddocksFromGroupMutation.mutateAsync({
        zoneGroupId: Number(id),
        paddockIds: removedPaddocks,
      })
    }
    if (addedPaddocks && addedPaddocks.length > 0) {
      await addPaddocksToGroupMutation.mutateAsync({
        zoneGroupId: Number(id),
        paddockIds: addedPaddocks,
      })
    }
  }

  return (
    <>
      <Group position="apart" mt="lg">
        <Text size="md" weight={700}>
          {t('paddocks.title', 'Paddocks in current group')}
        </Text>
        <ActionIcon radius="xl" onClick={open}>
          <FaPencilAlt />
        </ActionIcon>
      </Group>

      {paddocks.map((p) => (
        <Paper shadow="xs" mb="md" key={p.id}>
          <PaddockListItem name={p.name} boundary={p.boundary} />
        </Paper>
      ))}
      <Drawer opened={opened} onClose={close} title="Update Paddocks" position="right">
        <PaddockSelectionPage onNextClick={handleNextClick} paddocksInGroup={paddocks} />
      </Drawer>
    </>
  )
}
