import { DAYS_UNITL_MOWING_MAX, PORTAL_API_URL } from '@/config'
import dayjs from 'dayjs'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  GroupGrowth,
  PaddockGrowthDisplayable,
  PaddockMeasurementGroup,
} from './types/Growth.types'
import { orderBy } from 'lodash'
import _ from 'lodash'
import { fetchPaddockGrowths } from '@/api/paddockGrowthsApi'

const today = dayjs().toDate()

const getAllPaddockGrowths = async (
  farmId: number,
  targetPgr: number
): Promise<{
  today?: PaddockMeasurementGroup
  yesterday?: PaddockMeasurementGroup
  todayAverageGrowth: number
  diffAverageGrowth: number
}> => {
  // 4 days should be enough, 5 days is safe
  const _4daysAgo = dayjs(today).subtract(4, 'days')
  const growths = await fetchPaddockGrowths(farmId, targetPgr, _4daysAgo.toDate(), today)

  if (growths.length === 0)
    return {
      todayAverageGrowth: 0,
      diffAverageGrowth: 0,
    }

  const ordered = _(growths).orderBy((g) => dayjs(g.date).toDate().getTime(), 'desc')

  const [todayAverageGrowth, yesterdayAverageGrowth] = [0, 1].map(
    (s) =>
      ordered
        .drop(s)
        .take(3)
        .meanBy((d) => d.fitted_average_growth) ?? 0
  )

  const [day0, day1] = ordered.value()

  return {
    today: day0,
    yesterday: day1,
    todayAverageGrowth: _.round(todayAverageGrowth, 3),
    diffAverageGrowth: _.round(todayAverageGrowth - yesterdayAverageGrowth, 3),
  }
}

export const useAllPaddockGroupGrowths = (targetPgr: number, farmId?: string) => {
  return useQuery({
    enabled: !!farmId,
    queryKey: ['paddocksGrowths', farmId],
    queryFn: () => getAllPaddockGrowths(parseInt(farmId!), targetPgr),
  })
}

export const useYesterdayPaddockGroupGrowths = (
  groupName: string,
  targetPgr: number,
  farmId?: string
) => {
  return useQuery({
    enabled: !!farmId,
    queryKey: ['groupGrowthYest', groupName],
    queryFn: async () => {
      const dayBefore = dayjs(today).subtract(1, 'day').toDate()
      const allGrowths = await fetchPaddockGrowths(
        parseInt(farmId!),
        targetPgr,
        dayBefore,
        dayBefore
      )
      return allGrowths[0]?.zone_groups.find((zg) => zg.zone_group === groupName)
    },
  })
}

export const usePaddockGroupGrowths = (
  groupId: number,
  targetPgr: number,
  farmId?: string
): UseQueryResult<GroupGrowth> => {
  return useQuery({
    enabled: !!farmId,
    queryKey: ['paddock-group-growths', groupId],
    queryFn: async () => {
      const growths = await getAllPaddockGrowths(parseInt(farmId!), targetPgr)
      const allGrowths = growths.today
      const yestAllGrowths = growths.yesterday

      const todaysFilteredGrowth = allGrowths?.zone_groups.find(
        (zg) => zg.zone_group_id === groupId
      )
      const yestFilteredGrowth = yestAllGrowths?.zone_groups.find(
        (zg) => zg.zone_group_id === groupId
      )

      const todaysYield = Number(todaysFilteredGrowth?.aggregate.average_available_cover)

      const yestYield = Number(yestFilteredGrowth?.aggregate.average_available_cover)

      const diffYieldSinceLast = todaysYield - yestYield

      let daysUntilMowing = Math.max(
        0,
        Math.ceil((targetPgr - todaysYield) / Math.max(1, growths.todayAverageGrowth))
      )
      daysUntilMowing = Math.min(DAYS_UNITL_MOWING_MAX, daysUntilMowing)
      return {
        todaysYield,
        diffYieldSinceLast,
        todaysGrowth: growths.todayAverageGrowth,
        diffGrowthSinceLast: growths.diffAverageGrowth,
        daysUntilMowing,
      }
    },
  })
}

export const useFarmGrowths = (farmId?: string): UseQueryResult<GroupGrowth> => {
  return useQuery({
    enabled: !!farmId,
    queryKey: ['farmGrowth', farmId],
    queryFn: async () => {
      const growths = await getAllPaddockGrowths(parseInt(farmId!), 0)
      const allGrowths = growths.today
      const yestAllGrowths = growths.yesterday

      const todaysFilteredGrowths = allGrowths?.aggregate
      const yestFilteredGrowths = yestAllGrowths?.aggregate

      const todaysYield = Number(todaysFilteredGrowths?.average_available_cover)

      const yestYield = Number(yestFilteredGrowths?.average_available_cover)

      const diffYieldSinceLast = todaysYield - yestYield

      return {
        todaysYield,
        diffYieldSinceLast,
        todaysGrowth: growths.todayAverageGrowth,
        diffGrowthSinceLast: growths.diffAverageGrowth,
      }
    },
  })
}

export const usePaddockGrowths = (
  groupId: number,
  targetPgr: number,
  farmId?: string
): UseQueryResult<PaddockGrowthDisplayable[]> => {
  return useQuery({
    enabled: !!farmId,
    queryKey: ['paddock-growths', groupId],
    queryFn: async () => {
      const growths = await getAllPaddockGrowths(parseInt(farmId!), targetPgr)
      const allGrowths = growths.today

      const todaysFilteredGrowth = allGrowths?.zone_groups.find(
        (zg) => zg.zone_group_id === groupId
      )
      return orderBy(todaysFilteredGrowth?.growths, ['available_cover'], ['desc'])
    },
  })
}
