import { useUserContext } from '@/providers/user'
import { Select } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { Farm } from './types/farm-types'

export const Farms = () => {
  const { farms: allFarms, farm, setFarm } = useUserContext()
  const navigate = useNavigate()

  const handleFarmChange = (farm: Farm) => {
    setFarm(farm)
    const destination =
      farm.timeZone.toLowerCase() === 'pacific/auckland' ? '/dashboard/nz' : '/dashboard'
    navigate(destination)
  }

  const farms = allFarms!.map((f) => ({ label: f.name, value: f.id.toString() }))

  if (!allFarms || allFarms.length <= 1) {
    return <></>
  }

  return (
    <Select
      data={farms}
      defaultValue={farms[0].label}
      value={farm?.id.toString()}
      onChange={(fId) => handleFarmChange(allFarms.find((f) => f.id === Number(fId))!)}
      transitionProps={{ transition: 'pop-top-left', duration: 80, timingFunction: 'ease' }}
    />
  )
}
