import { MapHeaderLayout } from '@/components/Layout'
import { PaddockGroupHeader } from '@/components/PaddockGroupHeader'
import { PaddockGroupViewPaddocks } from '@/components/PaddockGroupPaddocks'
import { PaddockGroupSummary } from '@/components/PaddockGroupSummary'
import { Separator } from '@/components/Separator'
import { usePaddockUserActivityLogs, usePaddocks } from '@/features/paddocks/api/paddocksApi'
import { useUserContext } from '@/providers/user'
import { Container } from '@mantine/core'
import { useNavigate, useParams } from 'react-router-dom'
import { GrazingGroupHistory } from '../components/GrazingGroupHistory'
import { Growths } from '../components/Growths'
import { OptionsMenu } from '../components/OptionsMenu'
import { usePaddockGroup, usePaddockGroups } from '@/features/paddock-groups/api/paddockGroupsApi'
import { DefaultTarget } from '@/features/paddock-groups/types/group.type'
import NoPaddocksFoundAlert from '@/components/NoPaddocksFoundAlert'

export const ViewGrazingGroupPage = () => {
  const { id } = useParams()
  const { farm } = useUserContext()
  const { data: paddockGroups } = usePaddockGroups(farm?.id.toString())
  const { data: paddockGroup, isLoading } = usePaddockGroup(Number(id), farm?.id.toString())
  const { data: paddocks } = usePaddocks(farm?.id.toString())
  const { data: userActivityLogs } = usePaddockUserActivityLogs(
    paddockGroup?.id!,
    paddocks,
    paddockGroups
  )

  const navigate = useNavigate()

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!paddockGroup) {
    navigate('/grazing')
    return <>Not Found</>
  }

  return (
    <MapHeaderLayout
      rightIcon={<OptionsMenu paddocks={paddockGroup.paddocks} />}
      backClick={() => navigate('/grazing')}
    >
      <PaddockGroupHeader title={paddockGroup.name} area={paddockGroup.totalArea} />
      {!paddockGroup.paddocks || paddockGroup.paddocks.length === 0 ? (
        <NoPaddocksFoundAlert />
      ) : (
        <>
          <Separator />
          <PaddockGroupSummary target={paddockGroup.targets.pre ?? DefaultTarget.quantity.pre} />
          <Separator />
          <Container>
            <Growths paddockGroup={paddockGroup} />
          </Container>
        </>
      )}
      <Container>
        <PaddockGroupViewPaddocks paddocks={paddockGroup.paddocks} />
        {(userActivityLogs?.events ?? []).length ? (
          <GrazingGroupHistory
            userActivityLogs={userActivityLogs!}
            farmId={farm?.id!}
            paddockGroupId={paddockGroup.id}
          />
        ) : undefined}
      </Container>
    </MapHeaderLayout>
  )
}
