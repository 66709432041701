import { useRemovePaddocksFromGroup } from '@/features/paddock-groups/api/paddockGroupsApi'
import { Paddock } from '@/features/paddocks/types/Paddock.types'
import { useUserContext } from '@/providers/user'
import { Menu, ActionIcon } from '@mantine/core'
import { t } from 'i18next'
import { FaCog, FaExchangeAlt, FaTrash } from 'react-icons/fa'
import { useParams } from 'react-router-dom'

interface OptionsMenuProps {
  paddocks: Paddock[]
}

export const OptionsMenu = ({ paddocks }: OptionsMenuProps) => {
  const { id } = useParams()
  const { farm } = useUserContext()
  const removePaddocksFromGroupMutation = useRemovePaddocksFromGroup(farm?.id.toString())

  const handleDeleteClick = async () => {
    await removePaddocksFromGroupMutation.mutateAsync({
      zoneGroupId: Number(id),
      paddockIds: paddocks.map((p) => p.id),
    })
  }

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <ActionIcon size="xl" radius="xl" variant="light">
          <FaCog size={20} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          icon={
            <ActionIcon color="yellow.6" variant="light">
              <FaCog />
            </ActionIcon>
          }
        >
          {t('menu.settings', 'Settings')}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          icon={
            <ActionIcon color="blue.6" variant="light">
              <FaExchangeAlt />
            </ActionIcon>
          }
        >
          {t('menu.order', 'Change order')}
        </Menu.Item>
      </Menu.Dropdown>

      <Menu.Dropdown>
        <Menu.Item
          icon={
            <ActionIcon color="yellow.6" variant="light">
              <FaCog />
            </ActionIcon>
          }
        >
          {t('menu.settings', 'Settings')}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          icon={
            <ActionIcon color="red.6" variant="light">
              <FaTrash />
            </ActionIcon>
          }
          onClick={handleDeleteClick}
        >
          {t('menu.delete', 'Delete group')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
