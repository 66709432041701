import { PORTAL_API_URL } from '@/config'
import { PaddockMeasurementGroup } from '@/features/api/types/Growth.types'
import { axios } from '@/lib/axios'
import { auth } from '@/lib/firebase'

export const fetchPaddockGrowths = async (
  farmId: number,
  targetPgr: number,
  dateFrom: Date,
  dateTo: Date
): Promise<PaddockMeasurementGroup[]> => {
  const token = await auth.currentUser?.getIdToken()
  const { data } = await axios.get(
    `${PORTAL_API_URL}/paddocks/measurements/farm/${farmId}/from/${dateFrom.toISOString()}/to/${dateTo.toISOString()}/tpgr/${targetPgr}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return data
}
