import { useNavigate } from 'react-router'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '@/lib/firebase'
import { useEffect } from 'react'
import { useUserContext } from '@/providers/user'

export const LandingPage = () => {
  const [user, loading] = useAuthState(auth)
  const { farm, areFarmsLoading } = useUserContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (loading) {
      return
    }

    if (!user) {
      navigate('/auth/login')
    }

    if (areFarmsLoading) {
      return
    }

    if (farm) {
      navigate(farm.timeZone?.toLowerCase() === 'pacific/auckland' ? '/dashboard/nz' : '/dashboard')
    }
  }, [user, farm, loading, areFarmsLoading, navigate])

  return <></>
}
