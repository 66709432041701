import { auth } from '@/lib/firebase'
import { createContext, useContext, useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Farm } from '@/features/farms/types/farm-types'
import { useUserProfile } from '@/features/auth/hooks/useUserProfile'
import { useFarms } from '@/features/farms/api/farmsApi'

type UserContextProps = {
  isLoggedIn: boolean
  uid?: string
  displayName?: string
  email?: string
  farmGroupId?: string
  farms?: Farm[]
  areFarmsLoading?: boolean
  isFarmsLoadError?: boolean
  farm?: Farm
  setFarm: (farm: Farm) => void
}

const emptyContext = { isLoggedIn: false, setFarm: () => {} }

const UserContext = createContext<UserContextProps>(emptyContext)

type UserContextProviderProps = {
  children: React.ReactNode
}

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [farm, setFarm] = useState<Farm>()
  const [user] = useAuthState(auth)
  const userProfile = useUserProfile(user?.uid)
  const {
    data: farms,
    isLoading: areFarmsLoading,
    isError: isFarmsLoadError,
  } = useFarms(userProfile?.farmGroupId)

  useEffect(() => {
    if (farms) {
      setFarm(farms[0])
    }
  }, [farms])

  return (
    <UserContext.Provider
      value={{
        isLoggedIn: true,
        uid: '',
        displayName: userProfile?.displayName,
        email: userProfile?.email,
        farmGroupId: userProfile?.farmGroupId,
        farms,
        areFarmsLoading,
        isFarmsLoadError,
        farm,
        setFarm,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUserContext = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserContextProvider')
  }

  return context
}
