import { MAPPING_SERVICE_URL } from '@/config'
import { axios } from '@/lib/axios'
import { useQuery } from '@tanstack/react-query'
import { Farm } from '../types/farm-types'
import { auth } from '@/lib/firebase'

const getFarms = async (farmGroupId: number): Promise<Farm[]> => {
  const token = await auth.currentUser?.getIdToken()

  const { data } = await axios.get(`${MAPPING_SERVICE_URL}/api/farm-groups/${farmGroupId}/farms`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return data.map((d: any) => ({ id: d.farmId, name: d.name, timeZone: d.timeZone }))
}

export const useFarms = (farmGroupId?: string) => {
  return useQuery({
    enabled: !!farmGroupId,
    queryKey: ['farms', farmGroupId],
    queryFn: async () => {
      return await getFarms(parseInt(farmGroupId!))
    },
  })
}
