import { usePaddockGroupGrowths } from '@/features/api/growthsApi'
import { useUserContext } from '@/providers/user'
import { Group, Stack, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

type PaddockGroupSummaryProps = {
  cover?: number
  target: number
}

export const PaddockGroupSummary = ({ target, cover }: PaddockGroupSummaryProps) => {
  const { t } = useTranslation('paddock-group')
  const { id } = useParams()
  const { farm } = useUserContext()

  const { data: paddockGroupGrowths } = usePaddockGroupGrowths(
    Number(id),
    target,
    farm?.id.toString()
  )

  if (!paddockGroupGrowths) {
    return <></>
  }

  const coverSummary = cover ?? Number(paddockGroupGrowths.todaysYield)
  const growth = Number(paddockGroupGrowths.todaysGrowth)

  return (
    <Group position="apart" p="sm">
      <Stack spacing={0}>
        <Text fz="xs" color="gray.6">
          DM/ha
        </Text>
        <Text fz="xm" fw={700}>
          {coverSummary} Kg
        </Text>
      </Stack>
      {!isNaN(growth) && (
        <Stack spacing={0}>
          <Text fz="xs" color="gray.6">
            {t('headers.growth', 'Measured growth')}
          </Text>
          <Text fz="xm" fw={700}>
            {growth} Kg
          </Text>
        </Stack>
      )}
      <Stack spacing={0}>
        <Text fz="xs" color="gray.6">
          {t('headers.target', 'Target')}
        </Text>
        <Text fz="xm" fw={700}>
          {target} Kg
        </Text>
      </Stack>
    </Group>
  )
}
