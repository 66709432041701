import {
  UserActivityLogSummary,
  useDeletePaddockUserActivityLogs,
  useLoadMoreActivityLogs,
} from '@/features/paddocks/api/paddocksApi'
import { DisplayableUserActivityLog } from '@/features/paddocks/types/PaddockGroupHistory'
import { Timeline, Text, Modal, Stack, Button, ActionIcon, Flex } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { IconCheck, IconCircleChevronDown } from '@tabler/icons'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTrash } from 'react-icons/fa'

type PaddockGroupTimelineProps = {
  userActivityLogs: UserActivityLogSummary
  farmId: number
  paddockGroupId: number
}

export const PaddockGroupTimeline = ({
  userActivityLogs,
  farmId,
  paddockGroupId,
}: PaddockGroupTimelineProps) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const [paddockEventToDelete, setPaddockEventToDelete] = useState<DisplayableUserActivityLog>()

  const onDeleteIconClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    evt: DisplayableUserActivityLog
  ) => {
    e.stopPropagation()
    setPaddockEventToDelete(evt)
    setIsDeleteDialogOpen(true)
  }

  const deleteEventMutation = useDeletePaddockUserActivityLogs(farmId, paddockGroupId)
  const loadMoreEventsMutation = useLoadMoreActivityLogs(paddockGroupId)

  const deleteEvent = async () => {
    try {
      await deleteEventMutation.mutateAsync(paddockEventToDelete?.event!)
      showNotification({
        icon: <IconCheck size={18} />,
        color: 'teal',
        title: t('delete_paddock_event.notification_title', 'Success'),
        message: t('delete_paddock_event.notification_message', 'Event deleted with success.'),
      })

      setIsDeleteDialogOpen(false)
    } catch (err: unknown) {
      const message = err instanceof AxiosError ? err.response?.data?.message : String(err)

      showNotification({
        icon: <IconCheck size={18} />,
        color: 'orange',
        title: t('delete_paddock_event.notification_title', 'Error'),
        message: t(
          'delete_paddock_event.notification_message',
          `Failed to delete ${paddockEventToDelete?.display.eventName}. ${message}.`
        ),
      })
    }
  }

  const onDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false)
    setPaddockEventToDelete(undefined)
  }

  const onLoadMore = () => loadMoreEventsMutation.mutateAsync(userActivityLogs)

  return (
    <>
      <Timeline
        color="pink.6"
        active={userActivityLogs.hasMore ? userActivityLogs.events.length - 1 : Infinity}
        mt="md"
        lineWidth={2}
        bulletSize={16}
      >
        {userActivityLogs.events.map((entry) => (
          <Timeline.Item
            key={entry.event.eventId}
            title={
              <Flex>
                <Text>{entry.display.title}</Text>
                <ActionIcon color="gray.4" onClick={(e) => onDeleteIconClick(e, entry)}>
                  <FaTrash />
                </ActionIcon>
              </Flex>
            }
            bullet={<></>}
          >
            <Text color="dimmed" size="xs">
              {entry.display.subtitle}
            </Text>
          </Timeline.Item>
        ))}
        {userActivityLogs.hasMore ? (
          <Timeline.Item
            key="load-more"
            bullet={
              <ActionIcon disabled={loadMoreEventsMutation.isLoading}>
                <IconCircleChevronDown />
              </ActionIcon>
            }
            title="load more"
            lineVariant="dotted"
            onClick={onLoadMore}
            style={{ cursor: 'pointer' }}
          ></Timeline.Item>
        ) : undefined}
      </Timeline>

      {isDeleteDialogOpen ? (
        <DeleteUserEventDialog
          onDelete={deleteEvent}
          error={deleteEventMutation.error}
          onClose={onDeleteDialogClose}
          isLoading={deleteEventMutation.isLoading}
          eventType={paddockEventToDelete?.display.eventName!}
        ></DeleteUserEventDialog>
      ) : null}
    </>
  )
}

type DeleteUserEventDialogProps = {
  onDelete: () => Promise<void>
  onClose: () => void
  isLoading: boolean
  error: unknown
  eventType: string
}

export const DeleteUserEventDialog = ({
  onDelete,
  onClose,
  isLoading,
  eventType,
}: DeleteUserEventDialogProps) => {
  const { t } = useTranslation('paddock group')

  const handleDelete = async () => {
    await onDelete()
  }

  return (
    <Modal opened={true} onClose={onClose} withCloseButton={false} centered>
      <Text>{t('delete_confirmation', `Are you sure you want to delete this ${eventType}?`)}</Text>
      <Stack mt="lg">
        <Button loading={isLoading} onClick={handleDelete} color="red.6">
          {t('delete_confirmation_deletebtn', 'Delete')}
        </Button>
        <Button disabled={isLoading} onClick={onClose} variant="outline">
          {t('delete_confirmation_cancelbtn', 'Cancel')}
        </Button>
      </Stack>
    </Modal>
  )
}
