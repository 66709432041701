import { Button, Checkbox, Drawer, Flex, Stack, Text, createStyles, rem } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { IconGripVertical } from '@tabler/icons'
import { useListState } from '@mantine/hooks'
import { useUserContext } from '@/providers/user'
import { difference, fromPairs } from 'lodash'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { usePaddockOrder, useUpdatePaddockOrder } from '../api/paddockOrderApi'

const useStyles = createStyles((theme) => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: `${theme.spacing.sm} ${theme.spacing.xl}`,
    paddingLeft: `calc(${theme.spacing.xl} - ${theme.spacing.md})`, // to offset drag handle
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
    marginBottom: theme.spacing.sm,
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  dragHandle: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
  },
}))

type PaddockOrderType = {
  allPaddocks: string[]
  opened: boolean
  onClose: () => void
}

export const PaddockOrder = ({ allPaddocks, opened, onClose }: PaddockOrderType) => {
  const { t } = useTranslation('paddock-group')
  const { farm } = useUserContext()
  const { id } = useParams()
  const paddockOrder = usePaddockOrder(farm?.id!, Number(id)).data ?? []

  const { classes, cx } = useStyles()
  const [state, handlers] = useListState(paddockOrder)

  useEffect(() => {
    handlers.setState(paddockOrder)
    // eslint-disable-next-line
  }, [paddockOrder])

  const updatePaddockOrder = useUpdatePaddockOrder(farm?.id!, Number(id))

  const handleSave = async () => {
    await updatePaddockOrder.mutateAsync(fromPairs(state.map((key, index) => [key, index])))
    onClose()
  }

  if (!state) {
    return <></>
  }

  const items = state.map((paddock, index) => (
    <Draggable key={paddock} index={index} draggableId={paddock}>
      {(provided, snapshot) => (
        <div
          className={cx(classes.item, { [classes.itemDragging]: snapshot.isDragging })}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Checkbox
            value={paddock}
            label={
              <Flex>
                <div className={classes.dragHandle}>
                  <IconGripVertical size="1.05rem" stroke={1.5} />
                </div>
                <div>
                  <Text>{paddock}</Text>
                </div>
              </Flex>
            }
          />
        </div>
      )}
    </Draggable>
  ))

  const PaddockItem = (paddock: string) => (
    <div className={classes.item} key={paddock}>
      <Checkbox
        checked={false}
        onChange={(event) => {
          const isSelected = event.currentTarget.checked
          if (isSelected) {
            handlers.append(paddock)
          } else {
            handlers.remove(state.indexOf(paddock))
          }
        }}
        label={
          <div>
            <Text>{paddock}</Text>
          </div>
        }
      />
    </div>
  )

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      title={
        <Text size="lg" weight={600} align="center">
          {t('paddockOrder.title', 'Paddock Order')}
        </Text>
      }
      position="right"
      lockScroll={false}
    >
      <DragDropContext
        onDragEnd={({ destination, source }) =>
          handlers.reorder({ from: source.index, to: destination?.index || 0 })
        }
      >
        <Droppable droppableId="dnd-list" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <Checkbox.Group
                value={state}
                onChange={(newValues) => handlers.setState(newValues)}
                label="Select paddocks"
                description="only selected will be in rotation"
                withAsterisk
                mb={16}
              >
                {items}
              </Checkbox.Group>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {difference(allPaddocks, state).map((paddock) => PaddockItem(paddock))}
      <Stack mt="lg">
        <Button type="submit" color="green.9" onClick={handleSave}>
          {t('paddock_order.save_btn', 'Save')}
        </Button>
        <Button type="reset" variant="outline" onClick={onClose}>
          {t('paddock_order.cancel_btn', 'Cancel')}
        </Button>
      </Stack>
    </Drawer>
  )
}
