import { Box, Button, Center, Drawer, Flex, Stack, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { ReportDialog } from './ReportDialog'
import { useDisclosure } from '@mantine/hooks'
import { Paddock } from '@/features/paddocks/types/Paddock.types'
import { useUserContext } from '@/providers/user'
import { usePphProcessStatus, useUpdatePphProcessorStatus } from '@/features/data-processor-status'

type ReportGrazingProps = {
  suggestedPaddock: Paddock
  paddocks: Paddock[]
}

export const ReportGrazing = ({ suggestedPaddock, paddocks }: ReportGrazingProps) => {
  const { t } = useTranslation('grazing')
  const [opened, { open, close }] = useDisclosure(false)

  const { farm } = useUserContext()
  const { data: processingStatus } = usePphProcessStatus(farm?.id.toString())
  const updateProcessingStatus = useUpdatePphProcessorStatus(farm?.id.toString())

  const onOpen = async () => {
    await updateProcessingStatus.mutateAsync('reset')
    open()
  }

  const onClose = async () => {
    await updateProcessingStatus.mutateAsync('reset')
    close()
  }

  return (
    <Box
      m={32}
      p={16}
      maw={500}
      mx="auto"
      sx={(theme) => ({
        border: `1px solid ${
          theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
        }`,
        borderRadius: 5,
      })}
    >
      <Center>
        <Stack>
          <Text fz="lg" fw={600}>
            Were the cows at Paddock {suggestedPaddock.name} today?
          </Text>
          <Flex justify="center" gap={16}>
            <Button type="submit" mb="xl" mt="lg" color="green.9" onClick={onOpen}>
              {t('yes', 'Yes')}
            </Button>
            <Button variant="default" mb="xl" mt="lg" onClick={open}>
              {t('no', 'No')}
            </Button>
          </Flex>
        </Stack>
      </Center>
      <Drawer
        opened={opened && processingStatus !== 'complete'}
        onClose={onClose}
        title="Report Grazing"
        position="right"
      >
        <ReportDialog suggestedPaddock={suggestedPaddock} paddocks={paddocks} />
      </Drawer>
    </Box>
  )
}
